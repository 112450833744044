import React, { useEffect, useRef, useState } from 'react';

type RevealAnimationProps = {
	children: React.ReactNode;
	className?: string;
	delay?: number;
	direction?: 'up' | 'down' | 'left' | 'right' | 'none';
	threshold?: number;
};

export const RevealAnimation: React.FC<RevealAnimationProps> = ({
	children,
	className,
	delay = 0,
	direction = 'up',
	threshold = 0.1,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.unobserve(entry.target);
				}
			},
			{
				threshold,
			}
		);

		const currentRef = ref.current;
		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, [threshold]);

	const directionClass = {
		up: 'translate-y-10',
		down: '-translate-y-10',
		left: 'translate-x-10',
		right: '-translate-x-10',
		none: 'scale-95 opacity-0',
	};

	return (
		<div
			ref={ref}
			className={
				'transition-all duration-700 ease-out ' +
				(isVisible ? 'transform-none opacity-100' : `${directionClass[direction]} opacity-0`) +
				(className ? ` ${className}` : '')
			}
			style={{ transitionDelay: `${delay}ms` }}
		>
			{children}
		</div>
	);
};
