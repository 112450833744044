import { Table } from "../poker";
import * as fdb from "firebase/database";

// Interface for history state
export interface HistoryState<T> {
  canUndo: boolean;
  canRedo: boolean;
  undoDescription?: string;
  redoDescription?: string;
  undoPreview?: T;
  redoPreview?: T;
}

// Interface for history manager
export interface HistoryManager<T> {
  // Core operations
  undo(): Promise<T>;
  redo(): Promise<T>;
  
  // State information
  getState(): Promise<HistoryState<T>>;
  
  // History management
  pushState(state: T, description: string): Promise<void>;
  clearHistory(): Promise<void>;
}

// No-operation implementation for local use
export class LocalHistoryManager implements HistoryManager<Table> {
  constructor(tableId: string) {
    // No initialization needed
  }
  
  async undo(): Promise<Table> {
    throw new Error("Local undo not implemented");
  }
  
  async redo(): Promise<Table> {
    throw new Error("Local redo not implemented");
  }
  
  async getState(): Promise<HistoryState<Table>> {
    return {
      canUndo: false,
      canRedo: false
    };
  }
  
  async pushState(state: Table, description: string): Promise<void> {
    // No-op
  }
  
  async clearHistory(): Promise<void> {
    // No-op
  }
}

// Enhanced meta information interface for Firebase history
interface HistoryMeta {
  action: string;
  timestamp: number;
  prev?: string;
  next?: string;
}

// Firebase implementation for history management
export class FirebaseHistoryManager implements HistoryManager<Table> {
  private isUndoRedoInProgress: boolean = false;
  
  constructor(private tableId: string, private database = (window as any).firebase.database) {}
  
  async undo(): Promise<Table> {
    this.isUndoRedoInProgress = true;
    
    try {
      const currentRef = fdb.ref(this.database, `tables/${this.tableId}/current`);
      const currentSnapshot = await fdb.get(currentRef);
      const currentId = currentSnapshot.val();
      
      if (!currentId) {
        throw new Error("Cannot undo: No current state found");
      }
      
      const metaRef = fdb.ref(this.database, `tables/${this.tableId}/meta/${currentId}`);
      const metaSnapshot = await fdb.get(metaRef);
      const meta = metaSnapshot.val() as HistoryMeta;
      
      if (!meta || !meta.prev) {
        throw new Error("Cannot undo: No previous state found");
      }

      // Update the next pointer of the previous state to point to current
      // This enables redo functionality
      await fdb.update(fdb.ref(this.database, `tables/${this.tableId}/meta/${meta.prev}`), {
        next: currentId
      });
      
      // Set current to previous state
      await fdb.set(currentRef, meta.prev);
      
      // Get the table data
      const tableRef = fdb.ref(this.database, `tables/${this.tableId}/tables/${meta.prev}`);
      const tableSnapshot = await fdb.get(tableRef);
      
      return new Table(JSON.parse(tableSnapshot.val()));
    } catch (error) {
      console.error("Error during undo operation:", error);
      throw error;
    } finally {
      // Reset flag after operation
      this.isUndoRedoInProgress = false;
    }
  }
  
  async redo(): Promise<Table> {
    this.isUndoRedoInProgress = true;
    
    try {
      const currentRef = fdb.ref(this.database, `tables/${this.tableId}/current`);
      const currentSnapshot = await fdb.get(currentRef);
      const currentId = currentSnapshot.val();
      
      if (!currentId) {
        throw new Error("Cannot redo: No current state found");
      }
      
      const metaRef = fdb.ref(this.database, `tables/${this.tableId}/meta/${currentId}`);
      const metaSnapshot = await fdb.get(metaRef);
      const meta = metaSnapshot.val() as HistoryMeta;
      
      if (!meta || !meta.next) {
        throw new Error("Cannot redo: No next state found");
      }
      
      // Set current to next state
      await fdb.set(currentRef, meta.next);
      
      // Get the table data
      const tableRef = fdb.ref(this.database, `tables/${this.tableId}/tables/${meta.next}`);
      const tableSnapshot = await fdb.get(tableRef);
      
      return new Table(JSON.parse(tableSnapshot.val()));
    } catch (error) {
      console.error("Error during redo operation:", error);
      throw error;
    } finally {
      // Reset flag after operation
      this.isUndoRedoInProgress = false;
    }
  }
  
  async getState(): Promise<HistoryState<Table>> {
    try {
      const currentRef = fdb.ref(this.database, `tables/${this.tableId}/current`);
      const currentSnapshot = await fdb.get(currentRef);
      const currentId = currentSnapshot.val();
      
      if (!currentId) {
        return { canUndo: false, canRedo: false };
      }
      
      const metaRef = fdb.ref(this.database, `tables/${this.tableId}/meta/${currentId}`);
      const metaSnapshot = await fdb.get(metaRef);
      const meta = metaSnapshot.val() as HistoryMeta;
      
      if (!meta) {
        return { canUndo: false, canRedo: false };
      }
      
      let canUndo = false;
      let undoDescription = undefined;
      let undoPreview = undefined;
      
      if (meta.prev) {
        canUndo = true;
        undoDescription = meta.action;
        
        // Get previous state for preview
        const prevTableRef = fdb.ref(this.database, `tables/${this.tableId}/tables/${meta.prev}`);
        const prevTableSnapshot = await fdb.get(prevTableRef);
        if (prevTableSnapshot.exists()) {
          undoPreview = new Table(JSON.parse(prevTableSnapshot.val()));
        }
      }
      
      let canRedo = false;
      let redoDescription = undefined;
      let redoPreview = undefined;
      
      if (meta.next) {
        canRedo = true;
        
        // Get next meta for description
        const nextMetaRef = fdb.ref(this.database, `tables/${this.tableId}/meta/${meta.next}`);
        const nextMetaSnapshot = await fdb.get(nextMetaRef);
        const nextMeta = nextMetaSnapshot.val() as HistoryMeta;
        
        if (nextMeta) {
          redoDescription = nextMeta.action;
          
          // Get next state for preview
          const nextTableRef = fdb.ref(this.database, `tables/${this.tableId}/tables/${meta.next}`);
          const nextTableSnapshot = await fdb.get(nextTableRef);
          if (nextTableSnapshot.exists()) {
            redoPreview = new Table(JSON.parse(nextTableSnapshot.val()));
          }
        }
      }
      
      return {
        canUndo,
        canRedo,
        undoDescription,
        redoDescription,
        undoPreview,
        redoPreview
      };
    } catch (error) {
      console.error("Error getting history state:", error);
      return { canUndo: false, canRedo: false };
    }
  }
  
  async pushState(state: Table, description: string): Promise<void> {
    // Don't push during undo/redo operations
    if (this.isUndoRedoInProgress) {
      return;
    }
    
    try {
      const currentRef = fdb.ref(this.database, `tables/${this.tableId}/current`);
      const currentSnapshot = await fdb.get(currentRef);
      const currentId = currentSnapshot.val();
      
      if (!currentId) {
        console.warn("No current state found during pushState");
        return;
      }
      
      // Get the meta of the current state
      const metaRef = fdb.ref(this.database, `tables/${this.tableId}/meta/${currentId}`);
      const metaSnapshot = await fdb.get(metaRef);
      const meta = metaSnapshot.val() as HistoryMeta | null;
      
      if (meta && meta.next) {
        // If we're in the middle of history (there's a next pointer),
        // we need to clear all states after this one as we're creating a new branch
        await this.clearForwardHistory(currentId);
      }
      
      // Actual state pushing is handled by ServerProxyFirebase's updateTable
      // This method is mainly for clearing forward history when branching
    } catch (error) {
      console.error("Error during pushState:", error);
    }
  }
  
  private async clearForwardHistory(fromId: string): Promise<void> {
    try {
      // Get the meta of the current state
      const metaRef = fdb.ref(this.database, `tables/${this.tableId}/meta/${fromId}`);
      const metaSnapshot = await fdb.get(metaRef);
      const meta = metaSnapshot.val() as HistoryMeta | null;
      
      if (!meta || !meta.next) {
        return; // No forward history to clear
      }
      
      // Recursively clear forward states
      await this.deleteStateAndForward(meta.next);
      
      // Remove next pointer from current state
      await fdb.update(metaRef, { next: null });
    } catch (error) {
      console.error("Error clearing forward history:", error);
    }
  }
  
  private async deleteStateAndForward(stateId: string): Promise<void> {
    try {
      // Get the meta of this state
      const metaRef = fdb.ref(this.database, `tables/${this.tableId}/meta/${stateId}`);
      const metaSnapshot = await fdb.get(metaRef);
      const meta = metaSnapshot.val() as HistoryMeta | null;
      
      if (meta && meta.next) {
        // Recursively delete forward states first
        await this.deleteStateAndForward(meta.next);
      }
      
      // Delete this state's table and meta
      await Promise.all([
        fdb.remove(fdb.ref(this.database, `tables/${this.tableId}/tables/${stateId}`)),
        fdb.remove(metaRef)
      ]);
    } catch (error) {
      console.error("Error deleting state:", error);
    }
  }
  
  async clearHistory(): Promise<void> {
    try {
      // Get current state ID
      const currentRef = fdb.ref(this.database, `tables/${this.tableId}/current`);
      const currentSnapshot = await fdb.get(currentRef);
      const currentId = currentSnapshot.val();
      
      if (!currentId) {
        return; // No history to clear
      }
      
      // Get all states except current
      const metaRef = fdb.ref(this.database, `tables/${this.tableId}/meta`);
      const metaSnapshot = await fdb.get(metaRef);
      const allMeta = metaSnapshot.val() as Record<string, HistoryMeta>;
      
      if (!allMeta) {
        return; // No meta data
      }
      
      // Delete all states except current
      const deletePromises: Promise<void>[] = [];
      for (const [id, meta] of Object.entries(allMeta)) {
        if (id !== currentId) {
          deletePromises.push(
            fdb.remove(fdb.ref(this.database, `tables/${this.tableId}/meta/${id}`)),
            fdb.remove(fdb.ref(this.database, `tables/${this.tableId}/tables/${id}`))
          );
        }
      }
      
      // Clear prev and next pointers from current state
      deletePromises.push(
        fdb.update(fdb.ref(this.database, `tables/${this.tableId}/meta/${currentId}`), {
          prev: null,
          next: null
        })
      );
      
      await Promise.all(deletePromises);
    } catch (error) {
      console.error("Error clearing history:", error);
      throw new Error("Failed to clear history");
    }
  }
} 