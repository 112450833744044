import { ServerProxyFirebase } from "../../../models/server";
import React from "react";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import LoadingIcon from '@mui/icons-material/Autorenew';
import WarningIcon from '@mui/icons-material/Warning';
import VerifiedIcon from '@mui/icons-material/TaskAlt';
import { resolveShortId } from "../../../models/server/ServerProxyFirebase";



const turnstileBypassToken = import.meta.env.VITE_DEV_TURNSTILE_BYPASS_TOKEN;
const isTestingMode = !!turnstileBypassToken;

export function JoinGameSection({ navigate }: { navigate: (url: string) => void }) {
	const [shortTableId, setShortTableId] = React.useState('');
	const [turnstileStatus, setTurnstileStatus] = React.useState<'loading' | 'solved' | 'error'>('loading');
	const [tokenOrError, setTokenOrError] = React.useState<string | null>(null);
	const turnstileRef = React.useRef<TurnstileInstance>(null);

	const [actionState, setActionState] = React.useState<'joining-waiting' | 'creating-waiting' | 'joining' | 'creating' | null>(null);

	React.useEffect(() => {
		(async () => {
			if (!['joining-waiting', 'creating-waiting'].includes(actionState as any)) return;
			if (turnstileStatus !== 'solved') return;

			if (actionState === 'creating-waiting') {
				setActionState('creating');
				const tableId = await new ServerProxyFirebase().createTable({ turnstile: isTestingMode ? turnstileBypassToken : tokenOrError! });
				if (tableId) navigate('/play/' + tableId);

			} else if (actionState === 'joining-waiting') {
				setActionState('joining');
				const fullTableId = shortTableId.includes('+') ? shortTableId : await resolveShortId(tokenOrError!, shortTableId);
				if (fullTableId) navigate('/play/' + fullTableId);
				else {
					alert('The table you requested does not exist.');
				}
			}

			// Reset the states
			setActionState(null);
			setShortTableId('');
			setTurnstileStatus('loading');
			setTokenOrError(null);
			turnstileRef.current?.reset();

		})()
	}, [actionState, turnstileStatus, shortTableId, tokenOrError, navigate]);

	return (
		<div className="mx-auto">
			<div className="flex flex-col md:flex-row justify-center items-center self-stretch">

				<input className="join-game-input md:basis-48" type="text" inputMode="numeric" placeholder="table code" value={shortTableId} onChange={(e) => setShortTableId(e.target.value)} />

				<button id="join-table-btn" className="join-game-button md:basis-40" disabled={!(shortTableId === '' || shortTableId.match(/^\d{4,}$/)) || actionState === 'joining' || actionState === 'joining-waiting'} onClick={() => shortTableId !== '' && setActionState('joining-waiting')}>
					{
						actionState === 'joining-waiting' ?
							<>Verifying <div className="ml-2 animate-spin text-sm"><LoadingIcon /></div></>
							:
							actionState === 'joining' ?
								<>Joining <div className="ml-2 animate-spin text-sm"><LoadingIcon /></div></>
								:
								<>Join table</>
					}
				</button>

				<div className="mx-5 my-3 font-bold">or</div>

				<button id="create-table-btn" className="join-game-button create md:basis-80" disabled={actionState === 'creating' || actionState === 'creating-waiting'} onClick={() => setActionState('creating-waiting')}>
					{
						actionState === 'creating-waiting' ?
							<>Verifying <div className="ml-2 animate-spin text-sm"><LoadingIcon /></div></>
							:
							actionState === 'creating' ?
								<>Creating table <div className="ml-2 animate-spin text-sm"><LoadingIcon /></div></>
								:
								<>Create your own table</>
					}
				</button>
			</div>

			{/* https://developers.cloudflare.com/turnstile/get-started/client-side-rendering/ */}
			<div className="font-bold m-auto pt-5 text-sm text-center">
				{
					turnstileStatus === 'error' ?
						<><WarningIcon /> We are unable to verify if you are a human. Error {tokenOrError} </>
						:
						<span className="opacity-0">
							Protected by Cloudflare Turnstile
							<div className={`inline-block ml-2 opacity-25 ${turnstileStatus === 'loading' && 'animate-spin'}`}>
								{
									turnstileStatus === 'loading' && <LoadingIcon />
								}
								{
									turnstileStatus === 'solved' && <VerifiedIcon />
								}
							</div>
						</span>
				}
				{/* https://developers.cloudflare.com/turnstile/troubleshooting/testing/ */}
				<Turnstile
					ref={turnstileRef}
					siteKey={isTestingMode ? '1x00000000000000000000BB' : '0x4AAAAAAA0L3h5izxWv6gLR'}
					// siteKey='0x4AAAAAAA0L3h5izxWv6gLR'
					// siteKey='1x00000000000000000000BB'
					// siteKey='2x00000000000000000000AB'
					// siteKey='3x00000000000000000000FF'
					onError={(e) => { console.error(e); setTurnstileStatus('error'); setTokenOrError(e); }}
					onExpire={(t) => { setTurnstileStatus('loading'); setTokenOrError(null); }}
					onSuccess={(t) => { setTurnstileStatus('solved'); setTokenOrError(t); }}
				/>
				{
					isTestingMode ? <span>TEST MODE</span> : null
				}
			</div>
		</div>
	);
}
