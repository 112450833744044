import { Table } from "../poker";
import { TableId } from "../types";
import PubSub from "./PubSub";
import { ServerProxyInterface, PokerHandlers } from "./types";
import { HistoryManager, LocalHistoryManager } from "./HistoryManager";

export default class ServerProxyLocal implements ServerProxyInterface {
  private handlers: PokerHandlers | null = null;
  private isConnected = false;
  private tableId: TableId | null = null;
  history!: HistoryManager<Table>;

  registerHandlers(handlers: PokerHandlers) {
    this.handlers = handlers;
  }

  static clearTable(tableId: string) {
    localStorage.removeItem(`game-${tableId}`);
  }

  static getAllTableIds() {
    return Object.keys(localStorage).filter(x => x.startsWith('game-')).map(x => x.replace('game-', ''));
  }

  async createTable(opt: { tableId?: string, turnstile?: string }) {
    const tableId = opt.tableId ?? String(Math.floor(Math.random() * 1000 + 1000));
    const table = Table.getEmptyTable();
    localStorage.setItem(`game-${tableId}`, JSON.stringify(table));
    
    // Initialize history for new table
    this.history = new LocalHistoryManager(tableId);
    
    return tableId;
  }

  joinTable(tableId: TableId) {
    if (!tableId) {
      throw new Error('Table does not exist');
    }

    const tableString = localStorage.getItem(`game-${tableId}`);
    if (tableString === null) {
      this.handlers!.onTableDNE();
      return;
    }

    const table = new Table(JSON.parse(tableString));

    this.isConnected = true;
    this.tableId = tableId;
    
    // Initialize history manager when joining a table
    this.history = new LocalHistoryManager(tableId);
    
    this.handlers!.onConnect(table.copy());
    ((globalThis as any).pubsub as PubSub).register(tableId, this.handlers!);
  }

  updateTable(table: Table, reason: string) {
    if (!this.isConnected) {
      throw new Error('Cannot updateTable because server is not connected');
    }

    localStorage.setItem(`game-${this.tableId}`, JSON.stringify(table));
    
    ((globalThis as any).pubsub as PubSub).publish(this.tableId!, table.copy());
  }

  exitTable() {
    if (!this.isConnected) {
      throw new Error('Cannot exitTable because server is not connected');
    }

    ((globalThis as any).pubsub as PubSub).remove(this.tableId!, this.handlers!);
    this.isConnected = false;
  }

  createTableIfNotExists(tableId: TableId) {
    if (localStorage.getItem(`game-${tableId}`) === null) {
      this.createTable({ tableId });
    }
  }
}
