import { Table } from "../poker";
import { TableId } from "../types";
import { PokerHandlers, ServerProxyInterface } from "./types";
import { initializeApp } from "firebase/app";
import * as fdb from "firebase/database";
import { FirebaseHistoryManager, HistoryManager } from "./HistoryManager";

const database: fdb.Database = (window as any).firebase.database;

async function callCloudFunction(turnstile: string, mode: 'join' | 'create', prefix?: string): Promise<string | null> {
  const queryParam = new URLSearchParams({ turnstile, mode, prefix: prefix ?? '' });
  const response = await fetch('https://tablerequest-wop2ccmfja-uc.a.run.app?' + queryParam);
  const json = await response.json();
  if (response.ok) return json.fullId;
  if (response.status === 404) return null;
  console.error('Unhandled Error from cloud function', json);
  alert('An unexpected error occurred: ' + json.error);
  return null;
}

export async function resolveShortId(turnstile: string, shortId: string) {
  return await callCloudFunction(turnstile, 'join', shortId);
}

export default class ServerProxyFirebase implements ServerProxyInterface {
  private handlers?: PokerHandlers;
  private tableId?: TableId;
  private onConnectCalled = false;
  private unsubscribe1?: fdb.Unsubscribe;
  private unsubscribe2?: fdb.Unsubscribe;
  
  history!: HistoryManager<Table>;

  registerHandlers(handlers: PokerHandlers) {
    this.handlers = handlers;
  }

  playerId: string = '';

  generateId() {
    return `${+Date.now()}-${this.playerId}${Math.floor(Math.random() * 1000)}`;
  }

  async createTable(opt: { tableId?: string, turnstile?: string }) {
    if (!opt.turnstile) throw new Error('turnstile is required');
    if (opt.tableId) console.warn('the requested tableId is ignored during table creation', opt.tableId);

    const tableId = await callCloudFunction(opt.turnstile, 'create');
    if (!tableId) {
      return null;
    }
    
    const id = this.generateId();
    const emptyTable = Table.getEmptyTable();
    
    // Setup initial state with empty table
    await Promise.all([
      fdb.set(fdb.ref(database, `tables/${tableId}/current`), id),
      fdb.set(fdb.ref(database, `tables/${tableId}/tables/${id}`), JSON.stringify(emptyTable)),
      fdb.set(fdb.ref(database, `tables/${tableId}/meta/${id}`), { 
        action: 'initialize default table', 
        timestamp: +Date.now(),
        prev: null,
        next: null
      })
    ]);
    
    // Initialize history manager
    this.tableId = tableId;
    this.history = new FirebaseHistoryManager(tableId);
    
    return tableId;
  }

  private tableDataId: string = '';
  private metaCache: { 
    action: string, 
    timestamp: number, 
    prev?: string,
    next?: string 
  } | null = null;
  
  private async getMeta(): Promise<{ 
    action: string, 
    timestamp: number, 
    prev?: string,
    next?: string 
  }> {
    if (!this.tableId) throw new Error('tableId is not set');
    if (!this.tableDataId) { throw new Error('tableDataId is not set'); }
    if (this.metaCache) return this.metaCache;
    const meta = fdb.ref(database, `tables/${this.tableId}/meta/${this.tableDataId}`);
    const metaVal = await fdb.get(meta);
    return metaVal.val();
  }

  // NOTE: This can be cached for better performance & less bandwidth
  private async getTable(tableDataId: string): Promise<Table> {
    const table = fdb.ref(database, `tables/${this.tableId}/tables/${tableDataId}`);
    const tableVal = await fdb.get(table);
    const t = new Table(JSON.parse(tableVal.val()));
    return t;
  }

  async joinTable(tableId: TableId) {
    if (!(await fdb.get(fdb.ref(database, `tables/${tableId}`))).exists()) {
      this.handlers!.onTableDNE();
      return;
    }

    this.tableId = tableId;
    
    // Initialize history manager
    this.history = new FirebaseHistoryManager(tableId);

    const current = fdb.ref(database, `tables/${tableId}/current`);
    this.unsubscribe1 = fdb.onValue(current, async (snapshot) => {
      if (!snapshot.val()) {
        this.updateTable(Table.getEmptyTable(), 'initialize default table');
        return;
      }

      const newTableId = snapshot.val();
      if (!newTableId) {
        throw new Error("Table ID is missing");
      }

      this.tableDataId = newTableId;
      this.metaCache = null;
      const table = await this.getTable(this.tableDataId);

      if (this.onConnectCalled) {
        this.handlers!.onUpdate(table);
      } else {
        this.handlers!.onConnect(table);
        this.onConnectCalled = true;
      }
    });

    // https://firebase.google.com/docs/database/web/offline-capabilities?authuser=0#section-connection-state
    const connectedRef = fdb.ref(database, ".info/connected");
    this.unsubscribe2 = fdb.onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        console.log("connected");
      } else {
        console.log("not connected");
        this.handlers!.onDisconnect();
      }
    });
  }

  async updateTable(table: Table, reason: string) {
    if (!this.tableId) {
      throw new Error('Cannot updateTable because server is not connected');
    }
    
    try {
      // Check if table has changed before updating TODO: optimize
      if (this.tableDataId) {
        const currentTable = await this.getTable(this.tableDataId);
        if (JSON.stringify(table) === JSON.stringify(currentTable)) {
          console.log('skipping update because the table is the same');
          return;
        }
      }
      
      // Get the current table ID to update prev reference
      const currentRef = fdb.ref(database, `tables/${this.tableId}/current`);
      const currentSnapshot = await fdb.get(currentRef);
      const currentId = currentSnapshot.val();
      
      // Generate a new ID for this update
      const id = this.generateId();
      
      // Notify history manager to handle any redo branch clearing
      await this.history.pushState(table, reason);
      
      // Write the new state with prev pointing to current state
      await Promise.all([
        fdb.set(fdb.ref(database, `tables/${this.tableId}/meta/${id}`), { 
          action: reason, 
          timestamp: +Date.now(), 
          prev: currentId,
          next: null // Initially no next pointer
        }),
        fdb.set(fdb.ref(database, `tables/${this.tableId}/tables/${id}`), JSON.stringify(table)),
      ]);
      
      // Update current pointer to the new state
      await fdb.set(currentRef, id);
    } catch (error) {
      console.error("Error updating table:", error);
      throw new Error("Failed to update table: " + (error as Error).message);
    }
  }

  exitTable() {
    if (this.unsubscribe1) this.unsubscribe1();
    if (this.unsubscribe2) this.unsubscribe2();
    this.onConnectCalled = false;
    this.tableId = undefined;
  }
}
