import React from 'react';
import { motion } from 'framer-motion';

function rand(multiplier: number = 1) {
	return Math.random() * multiplier - multiplier / 2;
}

interface ShapeConfig {
	type: 'polygon' | 'rect' | 'circle';
	className: string;
	size: { width: number; height: number };
	props: Record<string, any>;
	style?: React.CSSProperties;
}

const shapes: ShapeConfig[] = [
	{
		type: 'polygon',
		className: 'fill-blue-300 dark:fill-blue-900',
		size: { width: 50, height: 50 },
		props: { points: '25,0 50,43.3 0,43.3' }
	},
	{
		type: 'rect',
		className: 'fill-green-300 dark:fill-purple-900',
		size: { width: 40, height: 40 },
		props: { width: 40, height: 40 }
	},
	{
		type: 'circle',
		className: 'fill-purple-300 dark:fill-green-900',
		size: { width: 50, height: 50 },
		props: { cx: 25, cy: 25, r: 25 }
	},
	{
		type: 'polygon',
		className: 'fill-yellow-300 dark:fill-yellow-900',
		size: { width: 50, height: 50 },
		props: { points: '25,0 48,17 39,45 11,45 2,17' }
	},
	{
		type: 'rect',
		className: 'fill-pink-300 dark:fill-pink-900',
		size: { width: 40, height: 40 },
		props: { width: 30, height: 30, x: 5, y: 5 },
		style: { transform: 'rotate(30deg)' }
	},
	{
		type: 'polygon',
		className: 'fill-red-300 dark:fill-red-900',
		size: { width: 30, height: 30 },
		props: { points: '15,0 30,15 15,30 0,15' }
	},
	{
		type: 'polygon',
		className: 'fill-indigo-300 dark:fill-indigo-900',
		size: { width: 50, height: 50 },
		props: { points: '25,0 47,14 47,36 25,50 3,36 3,14' }
	},
	{
		type: 'circle',
		className: 'fill-teal-300 dark:fill-teal-900',
		size: { width: 30, height: 30 },
		props: { cx: 15, cy: 15, r: 15 }
	},
	{
		type: 'polygon',
		className: 'fill-orange-300 dark:fill-orange-900',
		size: { width: 40, height: 40 },
		props: { points: '20,0 40,20 20,40 0,20' }
	},
	{
		type: 'circle',
		className: 'fill-cyan-300 dark:fill-cyan-900',
		size: { width: 30, height: 30 },
		props: { cx: 15, cy: 15, r: 15 }
	}
];

const GeometricBackground: React.FC = () => {
	// Function to generate random animation parameters
	const getRandomMotion = (index: number) => ({
		x: [0, rand(200), rand(100), rand(300), rand(100), rand(300)],
		y: [0, rand(100), rand(200), rand(100), rand(200), rand(100)],
		rotate: [0, Math.random() * 90],
		transition: {
			duration: Math.random() * 5 + 20,
			repeat: Infinity,
			repeatType: "reverse" as const,
			ease: "easeInOut",
			delay: index * 0.5,
		}
	});

	// Function to generate random initial position
	const getRandomPosition = (index: number) => {
		// Returns a checkerboard pattern with some random variation
		const columns = 3;
		const rows = 3;
		const vw = (index % columns) * 100 / columns + rand(12);
		const vh = Math.floor(index / columns) * 100 / rows + rand(24);

		return {
			left: `${vw + 10}%`,
			top: `${vh + 10}%`,
			scale: Math.random() * 0.5 + 2.5,
		};
	};

	const renderShape = (shape: ShapeConfig) => {
		switch (shape.type) {
			case 'polygon':
				return <polygon className={shape.className} {...shape.props} />;
			case 'rect':
				return <rect className={shape.className} {...shape.props} />;
			case 'circle':
				return <circle className={shape.className} {...shape.props} />;
			default:
				return null;
		}
	};

	return (
		<div className="fixed inset-0 overflow-hidden pointer-events-none">
			{shapes.map((shape, index) => (
				<motion.div
					key={index}
					className="absolute blur-[0.5px]"
					initial={getRandomPosition(index)}
					animate={getRandomMotion(index)}
				>
					<svg
						width={shape.size.width}
						height={shape.size.height}
						className="opacity-15"
						style={shape.style}
					>
						{renderShape(shape)}
					</svg>
				</motion.div>
			))}
		</div>
	);
};

export default GeometricBackground;
