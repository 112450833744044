import { FC } from "react";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link } from "react-router";

const HowItWorks: FC = () => {
	return (
		<div className="mt-16 md:mt-24 max-w-2xl mx-auto text-left px-3 md:px-6">
			<div className="relative overflow-hidden rounded-2xl backdrop-blur-[4px] p-8 md:p-10 shadow-2xl shadow-blue-500/20 border-2 border-blue-500/25   transition-all duration-300 transform hover:-translate-y-2 group">
				<div className="relative">
					<div className="flex items-center gap-4 mb-8 md:flex-col md:items-center md:gap-6">
						<div className="bg-gradient-to-br from-blue-500 to-indigo-500 rounded-2xl w-14 h-14 flex items-center justify-center flex-shrink-0 shadow-lg group-hover:scale-110 transition-all duration-300">
							<GroupAddIcon className="text-2xl text-white" />
						</div>
						<h3 className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 dark:from-blue-400 dark:to-indigo-400 bg-clip-text text-transparent">How This Works</h3>
					</div>
					<div className="text-base text-gray-700 dark:text-gray-200 space-y-4">
						<NumberStep number={1} text="Bring your physical cards and phones to the table" />
						<NumberStep number={2} text="One player creates a table, shares link or QR code" />
						<NumberStep number={3} text="Everyone joins and plays from their own phone" />
					</div>
				</div>
			</div>

			{/* Demo Section */}
			<div className="mt-8 md:mt-12 text-center px-4">
				<Link
					to="/tools/demo"
					className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white font-semibold rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 w-full sm:w-auto text-base"
				>
					<SmartphoneIcon className="text-xl" />
					Watch How It Works
				</Link>
			</div>
		</div>
	);
};

function NumberStep(props: { number: number, text: string }) {
	return (
		<div className="flex items-center gap-3 group">
			<span className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 dark:bg-blue-900/50 flex items-center justify-center text-blue-600 dark:text-blue-400 font-semibold group-hover:bg-blue-200 dark:group-hover:bg-blue-800 transition-colors">{props.number}</span>
			<p>{props.text}</p>
		</div>
	);
}

export default HowItWorks;
