import { FC } from "react";
import { Undo as UndoIcon } from '@mui/icons-material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { SvgIconComponent } from "@mui/icons-material";

interface FeatureCardProps {
	icon: SvgIconComponent;
	title: string;
	description: string;
}

const FeatureCard: FC<FeatureCardProps> = ({ icon: Icon, title, description }) => {
	return (
		<div className="feature-card group p-4 md:p-8 rounded-2xl backdrop-blur-[4px] shadow-lg md:shadow-2xl border-2 border-transparent md:hover:border-blue-500/25 transition-all duration-300 transform md:hover:-translate-y-2">
			<div className="flex md:block items-center">
				<div className="bg-blue-100 dark:bg-blue-900 rounded-full w-12 h-12 md:w-16 md:h-16 flex items-center justify-center md:mx-auto md:mb-6 mr-4 flex-shrink-0 md:group-hover:scale-110 transition-transform duration-300">
					<Icon className="text-xl md:text-3xl text-blue-600 dark:text-blue-400" />
				</div>
				<div className="flex-1 md:text-center">
					<h3 className="text-xl md:text-xl font-bold mb-2 md:mb-3">{title}</h3>
					<p className="text-gray-600 dark:text-gray-300 text-sm md:text-base">{description}</p>
				</div>
			</div>
		</div>
	);
};

const Features: FC = () => {
	const features = [
		{
			icon: PersonOffIcon,
			title: "No Sign-up Required",
			description: "Start playing instantly. No sign-ups, no downloads."
		},
		{
			icon: UndoIcon,
			title: "Undo/Redo",
			description: "Made a mistake? Easily undo or redo any action with a simple tap."
		},
		{
			icon: DarkModeIcon,
			title: "Dark & Light Mode",
			description: "Easy on your eyes, day or night. Automatically adapts to your system preferences."
		},
		{
			icon: WifiOffIcon,
			title: "Offline Games",
			description: "Going somewhere without internet? Start and play offline."
		},
	];

	return (
		<div className="mt-16 md:mt-24 max-w-4xl mx-auto text-left px-3 md:px-6 animation-delay-600">

			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 max-w-4xl mx-auto px-2 md:px-4">
				{features.map((feature) => (
					<FeatureCard
						key={feature.title}
						icon={feature.icon}
						title={feature.title}
						description={feature.description}
					/>
				))}
			</div>
		</div>
	);
};

export default Features;
