import { FC } from "react";
import { Clear } from '@mui/icons-material';
import React from "react";
import { Link, useNavigate, useParams } from "react-router";
import { JoinGameSection } from "./view/landingPage/JoinGameSection";
import Features from "./components/Features";
import HowItWorks from "./components/HowItWorks";
import { RevealAnimation } from "./view/landingPage/RevealAnimation";
import GeometricBackground from "./components/GeometricBackground";

const HomePage: FC = () => {
  React.useEffect(() => {
    window.dispatchEvent(new Event('vchips_resizeContainerStop'));
  }, []);

  const banner = window.location.hash;
  const { "*": splat } = useParams();
  const navigate = useNavigate();



  return (
    <div className="min-h-screen relative z-auto overflow-hidden">
      {/* Geometric background */}
      <GeometricBackground />

      <div className="relative z-10">

        {/* Error Messages Section */}
        <div className="p-5">
          {banner === '#table-dne' &&
            <div className="border border-red-400 bg-red-50 dark:bg-red-900 rounded m-2 p-2 flex justify-between text-lg font-bold">
              The table doesn't exist or isn't accepting new players.
              <Link to="/"><Clear /></Link>
            </div>
          }

          {splat ?
            <div className="border border-red-400 bg-red-50 dark:bg-red-900 rounded m-2 p-2 flex justify-between text-lg font-bold">
              404 Page Not Found: "{splat}" doesn't exist.
              <Link to="/"><Clear /></Link>
            </div>
            : null
          }
        </div>

        {/* Hero Section */}
        <div className="text-center px-4 py-14 md:py-20 md:mb-4 mx-auto flex flex-col gap-2 md:gap-8">
          <RevealAnimation delay={0}>
            <div className="text-2xl md:text-3xl font-bold tracking-tight italic text-gray-600/80 dark:text-gray-300/80">
              vchips.app
            </div>
          </RevealAnimation>
          <RevealAnimation delay={100}>
            <h1 className="text-5xl md:text-7xl vchips-text-gradient font-black leading-tight">
              <span className="">Virtual chips</span> for your <br className="hidden lg:block" /> IRL poker games
            </h1>
          </RevealAnimation>

          <RevealAnimation delay={250}>
            <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300">
              Play in-person poker without physical chips.
            </p>
          </RevealAnimation>
        </div>

        <RevealAnimation delay={175}>
          {/* Join/Create Game Section */}
          <div className="mx-4 mb-12 md:mb-16">
            <JoinGameSection navigate={navigate} />
          </div>
        </RevealAnimation>

        {/* How It Works Section */}
        <RevealAnimation delay={100}>
          <HowItWorks />
        </RevealAnimation>

        {/* Features Section */}
        <RevealAnimation delay={100}>
          <Features />
        </RevealAnimation>

        {/* Spacer */}
        <div className="h-12"></div>

        <div className="px-8 flex flex-col md:flex-row justify-center gap-4 mt-16">
          <Link to="/play/00+local?singlePhoneMode=1" className="button3">Start a Offline Game</Link>
          <Link to="/tools/demo" className="button3">Table Testing Page</Link>
        </div>

        <div className="h-24"></div>

      </div>

    </div>
  );
};

export default HomePage;

